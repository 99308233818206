import React, { Suspense, useMemo } from 'react';
import { Switch, Route } from 'react-router-dom';

import Routes from 'constants/Routes';

import CheckAuth from 'components/Router/CheckAuth';
import { withRouteSuspense } from 'hocs/withRouteSuspense';
const AdminPage = React.lazy(() => import('pages/admin/AdminPage'));
const AdminProjectsPage = React.lazy(() => import('pages/admin/AdminProjectsPage'));
const AdminProjectDetailPage = React.lazy(() => import('pages/admin/AdminProjectDetailPage'));
const AdminUserInfoPage = React.lazy(() => import('pages/admin/AdminUserInfoPage'));
const AdminCreateAdminPage = React.lazy(() => import('pages/admin/AdminCreateAdminPage'));
const AdminCreateUserPage = React.lazy(() => import('pages/admin/AdminCreateUserPage'));
const Register = React.lazy(() => import('pages/auth/RegisterPage'));
const ConfirmPage = React.lazy(() => import('pages/auth/ConfirmPage'));
const LoginPage = React.lazy(() => import('pages/auth/LoginPage'));
const ResetPasswordPage = React.lazy(() => import('pages/auth/ResetPasswordPage'));
const ProjectsPage = React.lazy(() => import('pages/project/ProjectsPage'));
const NewProjectPage = React.lazy(() => import('pages/project/NewProjectPage'));
const ProjectDetailPage = React.lazy(() => import('pages/project/ProjectDetailPage'));
const InterviewsPage = React.lazy(() => import('pages/interview/InterviewsPage'));
const ProfileOrWelcomePage = React.lazy(() => import('pages/profile/ProfileOrWelcomePage'));
const WelcomePage = React.lazy(() => import('pages/WelcomePage'));
const ProfilePage = React.lazy(() => import('pages/profile/ProfilePage'));
const ProfileEditPage = React.lazy(() => import('pages/profile/ProfileEditPage'));
const InterviewDetailPage = React.lazy(() => import('pages/interview/InterviewDetailPage'));
const EditProjectPage = React.lazy(() => import('pages/project/EditProjectPage'));
const BrowsePage = React.lazy(() => import('pages/interview/BrowsePage'));
const CheckoutPage = React.lazy(() => import('pages/payIn/CheckoutPage'));
const PayInResultPage = React.lazy(() => import('pages/payIn/PayInResultPage'));
const ScreenersPage = React.lazy(() => import('pages/screener/ScreenersPage'));
const CreateScreenerPage = React.lazy(() => import('pages/screener/CreateScreenerPage'));
const EditScreenerPage = React.lazy(() => import('pages/screener/EditScreenerPage'));
const ConfirmRegistration = React.lazy(() =>
    import('components/Auth/Register/ConfirmRegistration/ConfirmRegistration'),
);
const ProfilePublicPage = React.lazy(() => import('pages/profile/ProfilePublicPage'));
const ChatPage = React.lazy(() => import('pages/ChatPage'));
const InterviewPage = React.lazy(() => import('pages/interview/InterviewPage'));
const AdminPlansPage = React.lazy(() => import('pages/admin/AdminPlansPage'));
const ObserverPage = React.lazy(() => import('pages/interview/ObserverPage'));
const RecordingPage = React.lazy(() => import('pages/recording/RecordingPage'));
const DisputesPage = React.lazy(() => import('pages/dispute/DisputesPage'));
const AdminOrdersPage = React.lazy(() => import('pages/admin/AdminOrdersPage'));
const NotificationsPage = React.lazy(() => import('pages/NotificationsPage'));
const VouchersPage = React.lazy(() => import('pages/admin/VouchersPage'));
const PromoCodesPage = React.lazy(() => import('pages/admin/PromoCodesPage'));
const JoinExternalPage = React.lazy(() => import('pages/interview/JoinExternalPage'));
const DashboardPage = React.lazy(() => import('pages/dashboard/DashboardPage'));
const WaitingRoomPage = React.lazy(() => import('pages/interview/WaitingRoomPage'));
const ModeratorWaitingRoomPage = React.lazy(() => import('pages/interview/ModeratorWaitingRoomPage'));
const NewInterviewCreationFormPage = React.lazy(() => import('pages/interview/NewInterviewCreationPage'));
const InstanceWaitingRoomPage = React.lazy(() => import('pages/interview/InstanceWaitingRoomPage'));
const InviteResourcesPage = React.lazy(() => import('pages/interview/InviteResourcesPage'));
const ApplicationsPage = React.lazy(() => import('pages/interview/ApplicationsPage'));
const InvitationsPage = React.lazy(() => import('pages/interview/InvitationsPage'));
const TeamRegisterPage = React.lazy(() => import('pages/auth/TeamRegisterPage'));
const ExpiredLinkPage = React.lazy(() => import('pages/auth/ExpiredLinkPage'));
const CreateRolePage = React.lazy(() => import('pages/roles/CreateRolePage'));
const ViewRolePage = React.lazy(() => import('pages/roles/ViewRolePage'));
const EditRolePage = React.lazy(() => import('pages/roles/EditRolePage'));
const ToastNotifications = React.lazy(() => import('components/Notifications/ToastNotifications/ToastNotifications'));
const ViewAdminRolePage = React.lazy(() => import('pages/roles/ViewAdminRolePage'));
const DuplicateRolePage = React.lazy(() => import('pages/roles/DuplicateRolePage'));

export default function Router() {
    const MemoizedComponents = useMemo(() => ({
        ProjectDetailPage: withRouteSuspense(ProjectDetailPage, Routes.projectDetail.path),
        AdminPage: withRouteSuspense(AdminPage, Routes.admin.path),
        AdminProjectsPage: withRouteSuspense(AdminProjectsPage, Routes.adminProjects.path),
        AdminProjectDetailPage: withRouteSuspense(AdminProjectDetailPage),
        AdminOrdersPage: withRouteSuspense(AdminOrdersPage, Routes.adminOrders.path),
        BrowsePage: withRouteSuspense(BrowsePage, Routes.browseInterviews.path),
        AdminUserInfoPage: withRouteSuspense(AdminUserInfoPage),
        AdminCreateAdminPage: withRouteSuspense(AdminCreateAdminPage),
        AdminCreateUserPage: withRouteSuspense(AdminCreateUserPage),
        Register: withRouteSuspense(Register, Routes.signUp.path),
        TeamRegisterPage: withRouteSuspense(TeamRegisterPage),
        ExpiredLinkPage: withRouteSuspense(ExpiredLinkPage),
        ConfirmPage: withRouteSuspense(ConfirmPage),
        ConfirmRegistration: withRouteSuspense(ConfirmRegistration),
        LoginPage: withRouteSuspense(LoginPage, Routes.signIn.path),
        ResetPasswordPage: withRouteSuspense(ResetPasswordPage, Routes.resetPassword.path),
        ProjectsPage: withRouteSuspense(ProjectsPage, Routes.projects.path),
        NewProjectPage: withRouteSuspense(NewProjectPage, Routes.newProject.path),
        EditProjectPage: withRouteSuspense(EditProjectPage, Routes.editProject.path),
        InterviewsPage: withRouteSuspense(InterviewsPage, Routes.interviews.path),
        InviteResourcesPage: withRouteSuspense(InviteResourcesPage, Routes.inviteToInterviews.path),
        InvitationsPage: withRouteSuspense(InvitationsPage, Routes.interviewInvitations.path),
        ApplicationsPage: withRouteSuspense(ApplicationsPage, Routes.interviewApplications.path),
        InterviewDetailPage: withRouteSuspense(InterviewDetailPage, Routes.interviewDetail.path),
        ProfileOrWelcomePage: withRouteSuspense(ProfileOrWelcomePage, Routes.profileOrWelcome.path),
        WelcomePage: withRouteSuspense(WelcomePage, Routes.welcome.path),
        ProfilePage: withRouteSuspense(ProfilePage, Routes.profile.path),
        ProfileEditPage: withRouteSuspense(ProfileEditPage, Routes.profileEdit.path),
        CreateRolePage: withRouteSuspense(CreateRolePage, Routes.roleCreation.path),
        EditRolePage: withRouteSuspense(EditRolePage, Routes.role.path),
        DuplicateRolePage: withRouteSuspense(DuplicateRolePage, Routes.duplicateRole.path),
        ViewRolePage: withRouteSuspense(ViewRolePage, Routes.viewRoleId.path),
        ViewAdminRolePage: withRouteSuspense(ViewAdminRolePage, Routes.viewRole.path),
        CheckoutPage: withRouteSuspense(CheckoutPage, Routes.checkout.path),
        PayInResultPage: withRouteSuspense(PayInResultPage, Routes.payInResult.path),
        ScreenersPage: withRouteSuspense(ScreenersPage, Routes.screeners.path),
        CreateScreenerPage: withRouteSuspense(CreateScreenerPage, Routes.createScreener.path),
        EditScreenerPage: withRouteSuspense(EditScreenerPage, Routes.editScreener.path),
        ProfilePublicPage: withRouteSuspense(ProfilePublicPage, Routes.publicProfile.path),
        ChatPage: withRouteSuspense(ChatPage, Routes.chat.path),
        InterviewPage: withRouteSuspense(InterviewPage, Routes.interviewOnline.path),
        AdminPlansPage: withRouteSuspense(AdminPlansPage, Routes.plans.path),
        ObserverPage: withRouteSuspense(ObserverPage, Routes.observer.path),
        DisputesPage: withRouteSuspense(DisputesPage, Routes.disputes.path),
        NotificationsPage: withRouteSuspense(NotificationsPage, Routes.notifications.path),
        VouchersPage: withRouteSuspense(VouchersPage, Routes.vouchers.path),
        PromoCodesPage: withRouteSuspense(PromoCodesPage, Routes.promoCodes.path),
        JoinExternalPage: withRouteSuspense(JoinExternalPage, Routes.external.path),
        DashboardPage: withRouteSuspense(DashboardPage, Routes.dashboard.path),
        WaitingRoomPage: withRouteSuspense(WaitingRoomPage, Routes.waitingRoom.path),
        InstanceWaitingRoomPage: withRouteSuspense(InstanceWaitingRoomPage, Routes.instanceWaitingRoom.path),
        ModeratorWaitingRoomPage: withRouteSuspense(ModeratorWaitingRoomPage, Routes.moderatorWaitingRoom.path),
        NewInterviewCreationFormPage: withRouteSuspense(NewInterviewCreationFormPage, Routes.newInterviewCreation.path),
        RecordingPage: withRouteSuspense(RecordingPage, Routes.recording.path),
    }), []);

    return (
        <>
            <Switch>
                <Route
                    exact
                    path={Routes.home.path}
                    component={(props) => <CheckAuth {...props} route={Routes.home} component={null} />}
                />
                <Route
                    exact
                    path={Routes.admin.path}
                    component={(props) => <CheckAuth {...props} route={Routes.admin} component={MemoizedComponents.AdminPage} />}
                />
                <Route
                    exact
                    path={Routes.adminProjects.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminProjects} component={MemoizedComponents.AdminProjectsPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminProjectDetail.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminProjectDetail} component={MemoizedComponents.AdminProjectDetailPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminOrders.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminOrders} component={MemoizedComponents.AdminOrdersPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.browseInterviews.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.browseInterviews} component={MemoizedComponents.BrowsePage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminUserInfo.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminUserInfo} component={MemoizedComponents.AdminUserInfoPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminCreateAdmin.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminCreateAdmin} component={MemoizedComponents.AdminCreateAdminPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminCreateClient.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminCreateClient} component={MemoizedComponents.AdminCreateUserPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminCreateModerator.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminCreateModerator} component={MemoizedComponents.AdminCreateUserPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminCreateRespondent.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminCreateRespondent} component={MemoizedComponents.AdminCreateUserPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.adminCreateTranslator.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminCreateTranslator} component={MemoizedComponents.AdminCreateUserPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.signUp.path}
                    component={(props) => <CheckAuth {...props} route={Routes.signUp} component={MemoizedComponents.Register} />}
                />
                <Route
                    path={`${Routes.signUp.path}/:role`}
                    component={(props) => <CheckAuth {...props} route={Routes.signUp} component={MemoizedComponents.Register} />}
                />
                <Route
                    path={`${Routes.signUpTeam.path}/:token`}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.signUpTeam} component={MemoizedComponents.TeamRegisterPage} />
                    )}
                />
                <Route
                    path={Routes.expiredLink.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.expiredLink} component={MemoizedComponents.ExpiredLinkPage} />
                    )}
                />
                <Route exact path={Routes.confirm.path} component={MemoizedComponents.ConfirmPage} />
                <Route
                    path={`${Routes.confirmRegistration.path}`}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.confirmRegistration.path} component={MemoizedComponents.ConfirmRegistration} />
                    )}
                />
                <Route
                    exact
                    path={Routes.signIn.path}
                    component={(props) => <CheckAuth {...props} route={Routes.signIn} component={MemoizedComponents.LoginPage} />}
                />
                <Route
                    exact
                    path={Routes.resetPassword.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.resetPassword.path} component={MemoizedComponents.ResetPasswordPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.projects.path}
                    component={(props) => <CheckAuth {...props} route={Routes.projects} component={MemoizedComponents.ProjectsPage} />}
                />
                <Route
                    exact
                    path={Routes.newProject.path}
                    component={(props) => <CheckAuth {...props} route={Routes.newProject} component={MemoizedComponents.NewProjectPage} />}
                />
                <Route
                    exact
                    path={Routes.editProject.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.editProject} component={MemoizedComponents.EditProjectPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.projectDetail.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.projectDetail} component={MemoizedComponents.ProjectDetailPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.interviews.path}
                    component={(props) => <CheckAuth {...props} route={Routes.interviews} component={MemoizedComponents.InterviewsPage} />}
                />
                <Route
                    exact
                    path={Routes.adminProjectDetail.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.adminProjectDetail} component={MemoizedComponents.AdminProjectDetailPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.inviteToInterviews.path}
                    // eslint-disable-next-line react/no-children-prop
                    children={(props) => (
                        <CheckAuth {...props} route={Routes.inviteToInterviews} component={MemoizedComponents.InviteResourcesPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.interviewInvitations.path}
                    // eslint-disable-next-line react/no-children-prop
                    children={(props) => (
                        <CheckAuth {...props} route={Routes.interviewInvitations} component={MemoizedComponents.InvitationsPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.interviewApplications.path}
                    // eslint-disable-next-line react/no-children-prop
                    children={(props) => (
                        <CheckAuth {...props} route={Routes.interviewApplications} component={MemoizedComponents.ApplicationsPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.interviewDetail.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.interviewDetail} component={MemoizedComponents.InterviewDetailPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.profileOrWelcome.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.profileOrWelcome} component={MemoizedComponents.ProfileOrWelcomePage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.welcome.path}
                    component={(props) => <CheckAuth {...props} route={Routes.welcome} component={MemoizedComponents.WelcomePage} />}
                />
                <Route
                    exact
                    path={Routes.profile.path}
                    component={(props) => <CheckAuth {...props} route={Routes.profile} component={MemoizedComponents.ProfilePage} />}
                />
                <Route
                    exact
                    path={Routes.profileEdit.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.profileEdit} component={MemoizedComponents.ProfileEditPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.roleCreation.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.roleCreation} component={MemoizedComponents.CreateRolePage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.role.path}
                    component={(props) => <CheckAuth {...props} route={Routes.role} component={MemoizedComponents.EditRolePage} />}
                />
                <Route
                    exact
                    path={Routes.duplicateRole.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.duplicateRole} component={MemoizedComponents.DuplicateRolePage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.viewRoleId.path}
                    component={(props) => <CheckAuth {...props} route={Routes.viewRoleId} component={MemoizedComponents.ViewRolePage} />}
                />
                <Route
                    exact
                    path={Routes.viewRole.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.viewRole} component={MemoizedComponents.ViewAdminRolePage} />
                    )}
                />

                <Route
                    exact
                    path={Routes.checkout.path}
                    component={(props) => <CheckAuth {...props} route={Routes.checkout} component={MemoizedComponents.CheckoutPage} />}
                />
                <Route
                    exact
                    path={Routes.payInResult.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.payInResult} component={MemoizedComponents.PayInResultPage} />
                    )}
                />

                <Route
                    exact
                    path={Routes.screeners.path}
                    component={(props) => <CheckAuth {...props} route={Routes.screeners} component={MemoizedComponents.ScreenersPage} />}
                />

                <Route
                    exact
                    path={Routes.createScreener.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.createScreener} component={MemoizedComponents.CreateScreenerPage} />
                    )}
                />

                <Route
                    exact
                    path={Routes.editScreener.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.editScreener} component={MemoizedComponents.EditScreenerPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.publicProfile.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.publicProfile} component={MemoizedComponents.ProfilePublicPage} />
                    )}
                />
                <Route
                    path={Routes.chat.path}
                    component={(props) => <CheckAuth {...props} route={Routes.chat} component={MemoizedComponents.ChatPage} />}
                />
                <Route
                    exact
                    path={Routes.interviewOnline.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.interviewOnline} component={MemoizedComponents.InterviewPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.plans.path}
                    component={(props) => <CheckAuth {...props} route={Routes.plans} component={MemoizedComponents.AdminPlansPage} />}
                />
                <Route exact path={Routes.observer.path} component={MemoizedComponents.ObserverPage} />
                <Route exact path={Routes.observer.deprecatedPath} component={MemoizedComponents.ObserverPage} />
                <Route exact path={Routes.signalUser.path} component={MemoizedComponents.ObserverPage} />
                <Route
                    exact
                    path={Routes.disputes.path}
                    component={(props) => <CheckAuth {...props} route={Routes.disputes} component={MemoizedComponents.DisputesPage} />}
                />
                <Route
                    exact
                    path={Routes.notifications.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.notifications} component={MemoizedComponents.NotificationsPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.vouchers.path}
                    component={(props) => <CheckAuth {...props} route={Routes.vouchers} component={MemoizedComponents.VouchersPage} />}
                />
                <Route
                    exact
                    path={Routes.promoCodes.path}
                    component={(props) => <CheckAuth {...props} route={Routes.promoCodes} component={MemoizedComponents.PromoCodesPage} />}
                />
                <Route exact path={Routes.external.path} component={MemoizedComponents.JoinExternalPage} />
                <Route exact path={Routes.external.deprecatedPath} component={MemoizedComponents.JoinExternalPage} />
                <Route
                    exact
                    path={Routes.dashboard.path}
                    component={(props) => <CheckAuth {...props} route={Routes.dashboard} component={MemoizedComponents.DashboardPage} />}
                />
                <Route
                    exact
                    path={Routes.waitingRoom.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.waitingRoom} component={MemoizedComponents.WaitingRoomPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.instanceWaitingRoom.path}
                    component={(props) => (
                        <CheckAuth {...props} route={Routes.instanceWaitingRoom} component={MemoizedComponents.InstanceWaitingRoomPage} />
                    )}
                />
                <Route
                    exact
                    path={Routes.moderatorWaitingRoom.path}
                    component={(props) => (
                        <CheckAuth
                            {...props}
                            route={Routes.moderatorWaitingRoom}
                            component={MemoizedComponents.ModeratorWaitingRoomPage}
                        />
                    )}
                />
                <Route
                    exact
                    path={Routes.newInterviewCreation.path}
                    component={(props) => (
                        <CheckAuth
                            {...props}
                            route={Routes.newInterviewCreation}
                            component={MemoizedComponents.NewInterviewCreationFormPage}
                        />
                    )}
                />
                <Route
                    exact
                    path={Routes.recording.path}
                    component={(props) => <CheckAuth {...props} route={Routes.recording} component={MemoizedComponents.RecordingPage} />}
                />
            </Switch>
            {/*<Helmet>*/}
            {/*    <meta name="viewport" content="width=1366, initial-scale=1" />*/}
            {/*</Helmet>*/}
            <Suspense fallback={null}>
                <ToastNotifications />
            </Suspense>

        </>
    );
}
